import React from 'react'

import Typography from '@/components/ui/Typography'
import t from 'helpers/translation/getTranslation'

import { DigitalAssetVariantProps } from './types'
import s from "./styles.module.css"

function DigitalAssetVariant(props: DigitalAssetVariantProps) {
  const { rarity, heading, supply, ratio, hexColor } = props

  return (
    <div className={s.asset}>
      {
        rarity &&
        <Typography
          uppercase
          as="h4"
          variant={rarity}
          className={s.asset_rarity}>
          {rarity}
        </Typography>
      }

      <div className={s.asset_mint_details}>
        {
          hexColor && <span style={{ backgroundColor: hexColor }} className={s.color_chip} />
        }

        <ul>
          <Typography
            variant="default"
            as="li">
            {heading}
          </Typography>

          <Typography
            variant="secondary"
            as="li">
            {`${ratio}${t("pages.digitalAssets.assetRatioSupply")}`}
          </Typography>

          <Typography
            variant="secondary"
            as="li">
            {`${supply} ${t("pages.digitalAssets.assetTotalSupply")}`}
          </Typography>
        </ul>
      </div>
    </div>)
}

export default DigitalAssetVariant