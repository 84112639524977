import React from 'react'

import t from 'helpers/translation/getTranslation'

import SocialIcon from '../../../ui/SocialIcon/SocialIcon'
import StyledButton from '../../../ui/StyledButton'
import Typography from '../../../ui/Typography'
import s from './styles.module.css'
import { SocialStatProps } from './types'

/**
 * Individual social platform stat layout
 * @param props 
 * @returns 
 */
const SocialStat = (props: SocialStatProps) => {
  const { title, platform, membersCount, url } = props

  const linkText = t(`socials.follow.${platform}`)

  return (
    <div className={s.stat_container}>
      <h6 className={s.title}>{title}</h6>
      <div className={s.stat}>
        <SocialIcon platform={platform} className={s.icon} />
        <Typography
          variant="primary"
          className={s.count}
          as="span"
        >
          {membersCount}
        </Typography>
      </div>
      <StyledButton
        href={url}
        target="_blank"
        className={s.link}
        variant="filled"
        as="a">
        {linkText}
      </StyledButton>
    </div>
  )
}

export default SocialStat