import React from 'react'

import Conditional from '@/components/ui/Conditional'
import SocialBar from '@/components/shared/SocialBar'
import Typography from '@/components/ui/Typography'
import Image from '@/components/ui/Image'
import Html from '@/components/ui/Html'
import Link from '@/components/ui/Link'

import { TeamMemberType } from '@/network/team/types'

import s from './styles.module.css'

/**
 * Display information about a team member
 * @param props 
 * @returns 
 */
const TeamMember = (props: TeamMemberType) => {
  const { image, name, role, content, socials, href } = props

  return (
    <article className={s.container}>
      <div className={s.image_wrapper}>
        <Conditional
          wrap
          condition={!!href}
          component={
            // eslint-disable-next-line react/no-unstable-nested-components
            ({ children }) => (
              <Link
                // @ts-expect-error href will not be null because of conditional wrapper
                href={href}
                target="_blank">
                {children}
              </Link>
            )
          }>
          <Image
            notched
            className={s.image}
            src={image?.src}
            alt={image?.alt}
            width={500}
            height={500}
          />
        </Conditional>

      </div>
      <div>
        <Conditional
          wrap
          condition={!!href}
          component={
            // eslint-disable-next-line react/no-unstable-nested-components
            ({ children }) => (
              <Link
                // @ts-expect-error href will not be null because of conditional wrapper
                href={href}
                target="_blank">
                {children}
              </Link>
            )
          }>
          <header className={s.header}>
            <div>
              <Typography
                className={s.title}
                as="h3">
                {name}
              </Typography>
              <Typography
                className={s.role}
                uppercase
                variant="secondary"
                as="h4">
                {role}
              </Typography>
            </div>
            <SocialBar className={s.social_icons} links={socials} />
          </header>
        </Conditional>

        <Html
          className={s.content}
          html={content} />
      </div>
    </article>
  )
}

export default TeamMember
