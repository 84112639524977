import React from 'react'
import cn from 'classnames'

import { BLOCKCHAIN_TYPE_CARDANO, BLOCKCHAIN_TYPE_BNB, BLOCKCHAIN_TYPE_BASE, BLOCKCHAIN_TYPE_ETHEREUM } from 'consts/blockchain'
import { Base, Bnb, Cardano, Ethereum } from '@/components/ui/Icon'
import { Button, Link } from '@/components/ui'

import { TokenExchangeProps } from './types'
import s from './styles.module.css'

const TokenExchange = (props: TokenExchangeProps) => {
  const { href, logo, type, className, chain, linkText = "Buy", comingSoon = false } = props

  let chainElement

  switch (chain) {
    case BLOCKCHAIN_TYPE_BNB:
      chainElement = <Bnb className={s.chain_logo} />
      break

    case BLOCKCHAIN_TYPE_CARDANO:
      chainElement = <Cardano className={s.chain_logo} />
      break

    case BLOCKCHAIN_TYPE_ETHEREUM:
      chainElement = <Ethereum className={s.chain_logo} />
      break

    case BLOCKCHAIN_TYPE_BASE:
      chainElement = <Base className="h-10 w-[4.5rem]" />
      break

    default: break
  }

  return (
    <div
      key={href}
      className={cn(s.exchange, className)}>
      <div className={cn(s.exchange_col_name, type && s.has_type)}>
        {type && <span className={cn(s.exchange_type, s[type])}>{type.toUpperCase()}</span>}
        <span className={s.exchange_logo}>{logo}</span>
      </div>
      <div className={s.exchange_col_buy}>
        <span className={s.chain}>{chainElement}</span>
        {
          comingSoon ?
            <Button
              className={s.exchange_link}
              disabled
              filled
              variant="primary">
              Coming Soon
            </Button>
            :
            href &&
            <Link
              target="_blank"
              className={s.exchange_link}
              href={href}
              filled
              variant="primary">
              {linkText}
            </Link>
        }
      </div>
    </div>
  )
}

export default TokenExchange