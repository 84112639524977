import React from 'react'
import cx from 'classnames'

import { SectionType } from './type'
import s from './styles.module.css'

/**
 * Section component aids in correcting the scrollto position when the site header is fixed
 * @param props 
 * @returns 
 */
const Section = (props: SectionType) => {
  const { className, scrollTo, children, ...sectionProps } = props

  const classes = cx(scrollTo && s.section, className)

  return (
    <section className={classes} {...sectionProps}>
      {children}
    </section>
  )
}

Section.defaultProps = {
  className: null,
  children: null,
  scrollTo: false
}

export default Section