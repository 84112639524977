import { Typography } from '@/components/ui'
import React from 'react'

import s from './styles.module.css'

const GameRequirementList = () => {
  return (
    <div className={s.system_requirements}>
      <div className={s.system_requirement}>
        <Typography
          className={s.system_requirement_title}
          as="h4">
          <b>Minimum Specifications</b>
        </Typography>
        <Typography
          className={s.system_requirement_description}>
          Here are the minimum specs needed to play <i>Cornucopias</i>:
        </Typography>
        <ul>
          <li>
            <Typography>
              <b>OS:</b> 64-Bit Windows 10 or 64-Bit Windows 11<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>CPU:</b> Intel Core i5-10400 or AMD Ryzen 5 - 3600 <br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>RAM:</b> 16GB RAM<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>HDD:</b> 100GB HD - SSD highly recommended<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Video:</b> NVIDIA RTX 2060 or AMX RX 5600XT<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>DirectX: </b>Requires DirectX 12 compatible system<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Network:</b> Broadband Internet connection<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Sound Card:</b> DirectX Compatible<br />
            </Typography>
          </li>
        </ul>
      </div>
      <div className={s.system_requirement}>
        <Typography
          className={s.system_requirement_title}
          as="h4">
          <b>Recommended Specifications</b>
        </Typography>
        <Typography
          className={s.system_requirement_description}>
          Here are the recommended specs to play <i>Cornucopias</i>:
        </Typography>
        <ul>
          <li>
            <Typography>
              <b>OS:</b> 64-Bit Windows 10 or 64-Bit Windows 11<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>CPU:</b> Intel Core i7-10700K or AMD Ryzen 7 - 5800X <br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>RAM:</b> 16GB RAM<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>HDD:</b> 100GB HD - SSD highly recommended<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Video:</b> NVIDIA RTX 3080 or AMX RX 6800XT<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>DirectX: </b>Requires DirectX 12 compatible system<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Network:</b> Broadband Internet connection<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Sound Card:</b> DirectX Compatible<br />
            </Typography>
          </li>
        </ul>
      </div>
      <div className={s.system_requirement}>
        <Typography
          className={s.system_requirement_title}
          as="h4">
          <b>Best Experience Specifications</b>
        </Typography>
        <Typography
          className={s.system_requirement_description}>
          Here are the specs for the best experience in <i>Cornucopias</i>:
        </Typography>
        <ul>
          <li>
            <Typography>
              <b>OS:</b> 64-Bit Windows 10 or 64-Bit Windows 11<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>CPU:</b> Intel Core i9-12900K or AMD Ryzen 7 - 5900X <br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>RAM:</b> 32GB RAM<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>HDD:</b> 100GB HD - SSD highly recommended<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Video:</b> NVIDIA RTX 4080 or AMX RX 7900XTX<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>DirectX: </b>Requires DirectX 12 compatible system<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Network:</b> Broadband Internet connection<br />
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Sound Card:</b> DirectX Compatible<br />
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default GameRequirementList