import React from 'react'
import cn from 'classnames'

import { BLOCKCHAIN_TYPE_BASE, BLOCKCHAIN_TYPE_BNB, BLOCKCHAIN_TYPE_CARDANO, BLOCKCHAIN_TYPE_ETHEREUM } from 'consts/blockchain'
import { Base, Bnb, Cardano, Ethereum } from '@/components/ui/Icon'
import { Button, Link } from '@/components/ui'

import { TokenStakingPlatformProps } from './types'
import s from './styles.module.css'

const TokenStakingPlatform = (props: TokenStakingPlatformProps) => {
  const { href, helpHref, className, chains, linkText = "Stake", comingSoon = false } = props

  const chainElements = chains.map((chain) => {
    switch (chain) {
      case BLOCKCHAIN_TYPE_BNB:
        return <Bnb key={chain} className={s.chain_logo} />
      case BLOCKCHAIN_TYPE_CARDANO:
        return <Cardano key={chain} className={s.chain_logo} />
      case BLOCKCHAIN_TYPE_ETHEREUM:
        return <Ethereum key={chain} className={s.chain_logo} />
      case BLOCKCHAIN_TYPE_BASE:
        return <Base key={chain} className="h-8 w-[5.5rem]" />
      default: break
    }
  })

  return (
    <div
      className={cn(s.platform, className)}>
      <div className={s.chains}>
        {
          chainElements.map((item, index) => (
            <div key={index}>{item}</div>
          ))
        }
      </div>
      <div className={s.actions}>
        {
          helpHref &&
          <Link
            target="_blank"
            className={s.link}
            href={helpHref}
            outline>
            Guide
          </Link>
        }
        {
          comingSoon ?
            <Button
              className={s.link}
              disabled
              filled
              variant="primary">
              Coming Soon
            </Button>
            :
            href &&
            <Link
              target="_blank"
              className={s.link}
              href={href}
              filled
              variant="primary">
              {linkText}
            </Link>
        }
      </div>
    </div>
  )
}

export default TokenStakingPlatform