import React, { PropsWithChildren } from 'react'

import { SidebarConnectedNav } from '@/components/shared/Sidebar'
import { MasterLayout } from '@/components/shared/Layout'
import { t } from '@/helpers'

import {
  ROUTE_ABOUT_GAME_PLAY_YOUR_WAY,
  ROUTE_ABOUT_GAME_EXPLORATION,
  ROUTE_ABOUT_GAME_COMMERCE,
  ROUTE_ABOUT_GAME_RACING,
  ROUTE_ABOUT_GAME_COMBAT
} from '@/consts'

/**
 * Page layout for pages under Press
 * @param props 
 * @returns 
 */
const AboutGameLayout = (props: { subtitle: string } & PropsWithChildren) => {
  const { children, subtitle } = props

  const navigation = [
    {
      label: t("pages.aboutUs.playYourWay"),
      href: ROUTE_ABOUT_GAME_PLAY_YOUR_WAY
    },
    {
      label: t("pages.aboutUs.exploration"),
      href: ROUTE_ABOUT_GAME_EXPLORATION
    },
    {
      label: t("pages.aboutUs.commerce"),
      href: ROUTE_ABOUT_GAME_COMMERCE
    },
    {
      label: t("pages.aboutUs.racing"),
      href: ROUTE_ABOUT_GAME_RACING
    },
    {
      label: t("pages.aboutUs.combat"),
      href: ROUTE_ABOUT_GAME_COMBAT
    }
  ]

  return (
    <MasterLayout
      title={t("pages.aboutUs.title")}
      subtitle={subtitle}
      sidebar={(
        <SidebarConnectedNav options={navigation} />
      )}>
      {children}
    </MasterLayout>
  )
}

export default AboutGameLayout
