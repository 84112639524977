import React from 'react'

import Typography from '@/components/ui/Typography'
import Link from '@/components/ui/Link'
import Image from '@/components/ui/Image'

import { buildPath, cleanYoutubeDescription } from '@/helpers'

import s from './styles.module.css'
import { VideoFeaturedProps } from './types'

/**
 * Displays featured video tile
 * @param props 
 * @returns 
 */
const VideoFeatured = (props: VideoFeaturedProps) => {
  const { video, featuredTitle = "Featured", basePath } = props
  const { snippet, urlSlug } = video
  const { title, thumbnails, description } = snippet
  const { maxres, standard } = thumbnails

  return (
    <article className={s.wrapper}>
      <Typography
        as="h3"
        className="xl:hidden">
        {featuredTitle}
      </Typography>

      <Link
        href={buildPath(basePath, urlSlug)}
        className={s.link}>
        <Image
          alt={title}
          width={maxres ? maxres.width : standard.width}
          height={maxres ? maxres.height : standard.height}
          src={maxres ? maxres.url : standard.url} />
        <Typography
          as="p"
          variant="secondary"
          uppercase>{title}</Typography>
      </Link>

      <div className={s.description}>
        <Typography
          className="xl:block hidden"
          as="h3">
          {featuredTitle}
        </Typography>
        <Typography as="p">{cleanYoutubeDescription(description, 100, true)}</Typography>
      </div>
    </article>
  )
}

export default VideoFeatured

