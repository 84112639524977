import React from 'react'

import Error from '@/components/ui/Error'
import Typography from '@/components/ui/Typography'
import Divider from '@/components/ui/Divider'
import { ROUTE_VIDEO_GALLERY_BASE } from '@/consts'
import { buildPath, t } from '@/helpers'

import VideoFeatured from '../VideoFeatured'
import VideoPreviewList from '../VideoPreviewList'

import { VideoPlaylistProps } from './types'
import { VideoFeaturedType } from '../VideoFeatured/types'
import s from './styles.module.css'

/**
 * Display list of video previews + a featured video
 * @param props 
 * @returns 
 */
const VideoPlaylist = (props: VideoPlaylistProps) => {
  const { gallery, featured, playlist, error, featuredTitle } = props
  const { title, description, slug } = gallery

  return (
    <>
      <Typography as="h2" variant="primary">{title}</Typography>
      <Typography as="p" className={s.description}>{description}</Typography>

      {
        error ?
          <Error>{t("errors.generic")}</Error>
          :
          <>
            {
              featured &&
              <VideoFeatured
                video={featured as VideoFeaturedType}
                basePath={buildPath(ROUTE_VIDEO_GALLERY_BASE, slug)}
                featuredTitle={featuredTitle} />
            }

            <Divider className="md:hidden" />

            <VideoPreviewList
              basePath={buildPath(ROUTE_VIDEO_GALLERY_BASE, slug)}
              videos={playlist} />
          </>
      }
    </>
  )
}

export default VideoPlaylist