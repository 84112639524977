import React, { PropsWithChildren } from 'react'

import { MasterLayout } from '@/components/shared/Layout'

/**
 * Page layout for login / register / wizard
 * @param props 
 * @returns 
 */
const LoginLayout = (props: PropsWithChildren) => {
  const { children } = props


  return (
    <MasterLayout>
      <div className="md:w-1/2 md:min-w-[600px] w-full m-auto mt-1/3 xl:-mb-28">
        {children}
      </div>
    </MasterLayout>
  )
}

export default LoginLayout
