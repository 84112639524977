import React, { useMemo } from 'react'
import { useRouter } from 'next/router';
import queryString from 'query-string';

import { Typography } from '@/components/ui'
import TabList from '@/components/ui/Tabs/TabList';
import Tabs, { TabPanel, Tab } from '@/components/ui/Tabs'
import { ROUTE_REGISTER, ROUTE_LOGIN, ROUTE_AUTHENTICATE } from '@/consts'

import { AuthTabsProps } from './types'
import s from './styles.module.css'

const AuthTabs = (props: AuthTabsProps) => {
  const { children, title, isTabsHidden } = props
  const { pathname, query } = useRouter()

  // Grab current parameters and pass them into the tab urls
  const qs = queryString.stringify(query)

  const tabs = useMemo(() => {
    if (isTabsHidden) return

    return (
      <TabList>
        <Tab
          className={s.tab}
          activeClassName={s.active}
          href={`${ROUTE_LOGIN}${qs ? `?${qs}` : ''}`}
          isActive={[ROUTE_LOGIN, ROUTE_AUTHENTICATE].includes(pathname)}
          first
        >
          Sign In
        </Tab>
        <Tab
          className={s.tab}
          activeClassName={s.active}
          href={`${ROUTE_REGISTER}${qs ? `?${qs}` : ''}`}
          isActive={pathname === ROUTE_REGISTER}
        >
          Register
        </Tab>
      </TabList>
    )
  }, [isTabsHidden, pathname, qs])

  return (
    <Tabs>
      {tabs}
      <TabPanel>
        <Typography
          className={s.title}
          as="h3"
        >
          {title}
        </Typography>
        {children}
      </TabPanel>
    </Tabs>
  )
}

export default AuthTabs
