import React from 'react'

import Form, { FormGroup, Input, ValidationSummary } from '@/components/ui/Form'
import { PlayerHttpClient } from '@/network/httpClients'
import { ROUTE_LOGIN } from '@/consts'
import { Link, StyledButton, Typography } from '@/components/ui'
import t from 'helpers/translation/getTranslation'

import s from '../styles.module.css'

const ForgotPasswordForm = () => (
  <Form
    resetOnSuccess={{
      email: ''
    }}
    httpClient={(data) => PlayerHttpClient.UserPasswordRecovery(data)}
    renderForm={(({ register, errors, hasErrors, isSubmitting, isSubmitted }) => (
      <>
        <Typography mb='2' as="p">{t("pages.forgotPassword.formLabel")}</Typography>
        <FormGroup>
          <Input
            type="email"
            placeholder="Email"
            className={s.input}
            {...register('email', {
              required: {
                value: true,
                message: t("forms.emailRequired") as string
              }
            })} />
        </FormGroup>

        <ValidationSummary
          makeInvisible
          errors={errors}
          success={isSubmitted && !hasErrors ? (
            <>
              <p className='mb-2'>{t("pages.forgotPassword.submitSuccess")}</p>
              <p><Link href={ROUTE_LOGIN}>Click here to login.</Link></p>
            </>
          ) : undefined}
        />

        <StyledButton
          disabled={isSubmitting}
          className={s.button}
          color="primary"
          variant="filled"
          type="submit">
          Submit
        </StyledButton>

      </>
    ))} />

)

export default ForgotPasswordForm