/* eslint-disable react/no-array-index-key */
import React from 'react'

import { Drip, Tree } from '@/components/ui/Icon'

import Stat from './Stat'

import s from './styles.module.css'
import { SocialStatsProps } from './types'

// This may never change so half hardcoding for now
const partners = {
  "veritree": {
    logoUrl: '/_assets/partners/veritree.png',
    icon: Tree
  },
  "drop4drop": {
    logoUrl: '/_assets/partners/drop4drop.png',
    icon: Drip
  }
} as {
  [key: string]: {
    logoUrl: string,
    icon: React.ElementType
  }
}

/**
 * CopiCare widget that displays partnered stats
 * @param props 
 * @returns 
 */
const CopiCares = (props: SocialStatsProps) => {
  const { stats = [] } = props

  return (
    <div className={s.stats}>
      {stats.map((stat, index) => {
        const partner = partners[stat.partner]

        return <Stat
          key={index}
          title={stat.title}
          count={stat.count}
          partner={stat.partner}
          logoUrl={partner.logoUrl}
          icon={partner.icon} />
      })}
    </div>
  )
}

export default CopiCares