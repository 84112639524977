import React from 'react'

import { Attribution } from '@/components/ui'
import Separator from '@/components/ui/Separator'
import Typography from '@/components/ui/Typography'
import CopyButton from '@/components/ui/CopyButton'
import Counter from '@/components/ui/Counter'

import { usePrevious } from '@/hooks'

import s from './styles.module.css'
import { TokenPriceProps } from './types'

function TokenPrice(props: TokenPriceProps) {
  const {
    chain,
    price,
    pricePairTo,
    policyLabel = "POLICY ID",
    policyId,
    attributionHref,
    attributionLabel
  } = props

  const prevPrice = usePrevious(price)

  return (
    <div className={s.token_price}>
      <Typography
        as="h3"
        className={s.token_chain}
        uppercase>
        {chain}
      </Typography>

      <Typography as="span" uppercase>Current Price</Typography>

      <div className={s.price_wrapper}>
        <div className={s.price}>
          <Typography
            className={s.price_value}
            as="span"
            uppercase>
            <Counter
              decimals={5}
              from={prevPrice as number}
              to={price as number} />
          </Typography>

          <Typography
            uppercase
            className={s.price_pair}
            variant="primary">
            COPI<Separator className={s.separator} />{pricePairTo}
          </Typography>
        </div>
        {
          attributionLabel && attributionHref &&
          <Attribution label={attributionLabel} href={attributionHref} />
        }
      </div>

      <div className={s.policy}>
        <Typography as="span" uppercase>{policyLabel}: </Typography>
        <Typography as="p" className={s.policy_id}>
          {policyId}
          <CopyButton text={policyId} />
        </Typography>
      </div>
    </div>
  )
}

export default TokenPrice