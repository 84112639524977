import React from 'react'

import DataList, { Description, Term } from '@/components/ui/DataList'
import { CopyButton, Typography } from '@/components/ui'

import s from "./styles.module.css"
import { DigitalAssetMetadataProps } from './types'

function DigitalAssetMetadata(props: DigitalAssetMetadataProps) {
  const { metadata, policyId } = props

  return (
    <div>
      <DataList className={s.data_list}>
        {
          metadata.map((item, idx) => {
            const { label, value } = item

            const data = value instanceof Array ? value.join("<br/>") : value

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className={s.data_item} key={idx}>
                <Term>
                  <Typography
                    as="span"
                    uppercase
                    variant="secondary">{label}</Typography>
                </Term>
                <Description>
                  <Typography
                    as="span"
                    dangerouslySetInnerHTML={{ __html: data }} />
                </Description>
              </div>
            )
          })

        }
      </DataList>

      {
        policyId &&
        <div>
          <Term>
            <Typography
              as="span"
              uppercase
              variant="secondary">Policy Id</Typography>
          </Term>
          <Description>
            <Typography
              className={s.policyId}
              as="span">
              {policyId}
              <CopyButton text={policyId} />
            </Typography>
          </Description>
        </div>
      }
    </div>

  )
}

export default DigitalAssetMetadata