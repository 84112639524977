import React from 'react'

import Image from '@/components/ui/Image'
import Typography from '@/components/ui/Typography'

import s from './styles.module.css'
import { FeatureImageProps } from './types'
import ColumnLayout from '../ColumnLayout'

/**
 * Display an image with a heading (Used in About pages)
 * @param props 
 * @returns 
 */
const FeatureImage = (props: FeatureImageProps) => {
  const { src, heading, caption } = props

  return (
    <ColumnLayout
      rowClassName={s.row}
      leftClassName={s.left}
      rightClassName={s.right}
      left={(
        <Image
          notched
          src={src}
          width={700}
          height={400}
          className={s.image}
          caption={caption} />
      )}
      right={(
        <Typography as='h3'>
          {heading}
        </Typography>
      )} />
  )
}

export default FeatureImage
