/* eslint-disable react/no-array-index-key */
import React from 'react'

import SocialStat from './SocialStat'

import s from './styles.module.css'
import { SocialStatsProps } from './types'

/**
 * Display stats for specified social platform from props
 * @param props 
 * @returns 
 */
const SocialStats = (props: SocialStatsProps) => {
  const { stats = [] } = props

  return (
    <div className={s.social_stats}>
      {stats.map((stat, index) => <SocialStat key={index}  {...stat} />)}
    </div>
  )
}

export default SocialStats