import React from 'react'

import Stat from '@/components/ui/Stat'
import Image from '@/components/ui/Image'

import s from './styles.module.css'
import { StatProps } from './types'

/**
 * Stat displaying logo, icon and a statistic
 * @param props 
 * @returns 
 */
const StatWithLogo = (props: StatProps) => {
  const { title, count, icon, logoUrl, partner } = props

  const Icon = icon

  return (
    <div className={s.stat_container}>

      {
        logoUrl &&
        <Image
          alt={`${partner} logo`}
          className={s.logo}
          src={logoUrl}
          width={220}
          height={80}
        />
      }

      {Icon && <Icon className={s.icon} />}

      <Stat title={title} count={count} />
    </div>
  )
}

export default StatWithLogo