import React, { useState } from 'react'
import { signIn, getSession } from 'next-auth/react'
import { useForm } from 'react-hook-form'

import { CREDENTIALS_PROVIDER_ID } from 'consts/auth'
import { FormGroup, Input, ValidationSummary } from '@/components/ui/Form'
import StyledButton from '@/components/ui/StyledButton'
import t from 'helpers/translation/getTranslation'
import { LOGIN_EMAIL_VALIDATION, LOGIN_PASSWORD_VALIDATION } from '@/utils/Validation'

import { LoginFormProps } from './types'
import s from '../styles.module.css'
const LoginForm = (props: LoginFormProps) => {
  const { onSetMfaCredentials, onSetAccountCredentials, onSubmit: propsOnSubmit } = props
  const [submitting, setSubmitting] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    resetField,
    setFocus,
    setError,
    formState: { errors: formErrors }
  } = useForm()

  const onAuthError = (errorMessage?: string) => {
    // Clear password field and set focus
    resetField('password')
    setFocus('password')
    setError('root', { type: '400', message: errorMessage || t("pages.login.credentialsError") as string })
  }

  const onSubmit = handleSubmit(async (data) => {
    setSubmitting(true)

    const res = await signIn(CREDENTIALS_PROVIDER_ID, { ...data, redirect: false })

    if (res?.error) {
      onAuthError(res?.error)
      setSubmitting(false)
    }
    else {
      const session = await getSession()

      if (session?.unconfirmed) {
        const { email, password } = data;

        setSubmitting(false)

        return onSetAccountCredentials({ email, password })
      }

      if (session?.forceMfa) {
        const { user, sessionToken } = session

        return onSetMfaCredentials({ id: user.id, sessionToken: sessionToken as string })
      }

      await propsOnSubmit()
    }
  })

  return (
    <form
      className="mb-6"
      onSubmit={onSubmit}>
      <FormGroup>
        <Input
          type="email"
          placeholder="Email"
          className={s.input}
          {...register('email', LOGIN_EMAIL_VALIDATION)}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="password"
          placeholder="Password"
          className={s.input}
          {...register('password', LOGIN_PASSWORD_VALIDATION)} />
      </FormGroup>
      <ValidationSummary
        makeInvisible
        errors={formErrors}
      />
      <StyledButton
        disabled={submitting}
        loading={submitting}
        className={s.button}
        color="primary"
        variant="filled"
        type="submit"
        value="Login">
        {t("pages.login.loginButton")}
      </StyledButton>
    </form>
  )
}

export default LoginForm
