import React from 'react'
import { Base, Bnb, Cardano, Ethereum } from '@/components/ui/Icon'
import formatNumber from 'helpers/formatNumber'
import { Blockchain } from '@/types/Blockchain'

import { CopiBalanceProps } from './types'
import s from './styles.module.css'

const CopiBalance = (props: CopiBalanceProps) => {
  const { balances = [] } = props

  const chainBalances: Partial<Record<Blockchain, number>> = {
    cardano: 0,
    base: 0,
    bnb: 0,
    ethereum: 0
  }

  balances.forEach(balance => {
    chainBalances[`${balance.blockchain.toLowerCase() as Blockchain}`] = balance.balance
  })

  return (
    <ul>
      <li className={s.balance_item}>
        <span className={s.balance_label}>
          <Bnb className="w-28 h-5" />
        </span>
        <span
          title={`${chainBalances.bnb?.toLocaleString()} COPI`}
          className={s.balance_value}>
          {formatNumber(chainBalances.bnb ?? 0)}
        </span>
      </li>
      <li className={s.balance_item}>
        <span
          className={s.balance_label}>
          <Cardano className="w-24 h-5" />
        </span>
        <span
          title={`${chainBalances.cardano?.toLocaleString()} COPI`}
          className={s.balance_value}>
          {formatNumber(chainBalances.cardano ?? 0)}
        </span>
      </li>
      <li className={s.balance_item}>
        <span className={s.balance_label}>
          <Ethereum className="w-24 h-5" />
        </span>
        <span
          title={`${chainBalances.ethereum?.toLocaleString()} COPI`}
          className={s.balance_value}>
          {formatNumber(chainBalances.ethereum ?? 0)}
        </span>
      </li>
      <li className={s.balance_item}>
        <span className={s.balance_label}>
          <Base className="w-16 h-4" />
        </span>
        <span
          title={`${chainBalances.base?.toLocaleString()} COPI`}
          className={s.balance_value}>
          {formatNumber(chainBalances.base ?? 0)}
        </span>
      </li>
    </ul>
  )
}

export default CopiBalance