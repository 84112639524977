import React from 'react'

import TokenStakingPlatform from './TokenStakingPlatform'
import { TokenStakingProps } from './types'
import s from './styles.module.css'

const TokenStaking = (props: TokenStakingProps) => {
  const { platforms = [], linkText = "Stake" } = props

  return (
    <div className={s.list}>
      {
        platforms.map((platform) => (
          <TokenStakingPlatform
            key={platform.href}
            linkText={linkText}
            className={s.item}
            {...platform} />
        ))
      }
    </div>
  )
}

export default TokenStaking