import React, { PropsWithChildren } from 'react'

import { SidebarConnectedNav } from '@/components/shared/Sidebar'
import { MasterLayout } from '@/components/shared/Layout'

import {
  ROUTE_LEGAL_PRIVACY_POLICY,
  ROUTE_LEGAL_TERMS_OF_SERVICE
} from '@/consts'

import t from 'helpers/translation/getTranslation'

/**
 * Page layout for pages under legal
 * @param props 
 * @returns 
 */
const LegalLayout = (props: { subtitle: string } & PropsWithChildren) => {
  const { children, subtitle } = props

  const navigation = [
    {
      label: t("pages.legal.serviceTitle"),
      href: ROUTE_LEGAL_TERMS_OF_SERVICE
    },
    {
      label: t("pages.legal.policyTitle"),
      href: ROUTE_LEGAL_PRIVACY_POLICY
    }
  ]

  return (
    <MasterLayout
      title={t("pages.legal.title")}
      subtitle={subtitle}
      sidebar={(
        <SidebarConnectedNav options={navigation} />
      )}>
      {children}
    </MasterLayout>
  )
}

export default LegalLayout
