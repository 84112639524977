import React from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { ColumnLayoutProps } from './types'

/**
 * Displays a row with 2 columns (Used on About pages)
 * @param props 
 * @returns 
 */
const ColumnLayout = (props: ColumnLayoutProps) => {
  const { left, right, leftClassName, rightClassName, rowClassName } = props

  const rowClasses = cx(s.row, rowClassName)
  const leftClasses = cx(s.l_column, leftClassName)
  const rightClasses = cx(s.r_column, rightClassName)

  return (
    <div className={rowClasses}>
      <div className={leftClasses}>
        {left}
      </div>
      <div className={rightClasses}>
        {right}
      </div>
    </div>
  )
}

export default ColumnLayout
