import React from 'react'
import Router from 'next/router'

import Form, { FormGroup, Input, ValidationSummary } from '@/components/ui/Form'
import { PlayerHttpClient } from '@/network/httpClients'
import { StyledButton, Typography } from '@/components/ui'

import {
  ROUTE_LOGIN,
} from '@/consts'
import { PASSWORD_VALIDATION, CONFIRM_PASSWORD_VALIDATION } from '@/utils/Validation'

import t from 'helpers/translation/getTranslation'
import s from '../styles.module.css'

type RecoverPasswordFormType = {
  sub: string
  code: string
}

const RecoverPasswordForm = (props: RecoverPasswordFormType) => {
  const { sub, code } = props

  const onSubmit = () => {
    // Redirect user to login page after 2 seconds
    setTimeout(() => {
      Router.push(ROUTE_LOGIN)
    }, 2000)
  };

  return (
    <Form
      resetOnSuccess={
        {
          password: '',
          passwordConfirmation: ''
        }
      }
      onSuccess={onSubmit}
      httpClient={(data) => PlayerHttpClient.UserPasswordRecoveryConfirm({ sub, code, ...data })}
      renderForm={(({ register, errors, isSubmitting, isSubmitted, hasErrors, watch }) => (
        <>
          <Typography mb='2' as="p">{t("pages.recoverPassword.formLabel")}</Typography>
          <FormGroup>
            <Input
              className={s.input}
              type="password"
              placeholder="New Password"
              {...register('password', PASSWORD_VALIDATION)}
            />
          </FormGroup>

          <FormGroup>
            <Input
              className={s.input}
              type="password"
              placeholder="Confirm New Password"
              {...register('passwordConfirmation', {
                ...CONFIRM_PASSWORD_VALIDATION,
                validate: (val: string) => {
                  if (watch('password') !== val) {
                    return t("forms.passwordDoNotMatch") as string;
                  }
                },
              })} />
          </FormGroup>

          <ValidationSummary
            makeInvisible
            errors={errors}
            success={!hasErrors && isSubmitted ? t("pages.recoverPassowrd.submitSuccess") : undefined}
          />

          <StyledButton
            disabled={isSubmitting}
            className={s.button}
            color="primary"
            variant="filled"
            type="submit">
            Submit
          </StyledButton>
        </>
      ))} />

  )
}

export default RecoverPasswordForm