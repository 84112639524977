import React from 'react'
import { motion } from 'framer-motion'

import Image from '@/components/ui/Image'
import Link from '@/components/ui/Link'
import Typography from '@/components/ui/Typography'

import { buildPath, t } from '@/helpers'

import { VideoPreviewProps } from './types'
import s from './styles.module.css'

/**
 * Display thumbnail and title video tile
 * @param props 
 * @returns 
 */
const VideoPreview = (props: VideoPreviewProps) => {
  const { video, playing, basePath } = props
  const { snippet, id, urlSlug } = video
  const { title, thumbnails, resourceId } = snippet
  const { url, width, height } = thumbnails.medium
  const { videoId } = resourceId
  const isPlaying = playing === videoId

  return (
    <motion.div
      className={s.link}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5
      }}>
      <Link
        href={buildPath(basePath, urlSlug)}
        key={id}>
        <div className={isPlaying ? s.playing : undefined}>
          {
            isPlaying && <Typography as="span" variant="primary">{t("pages.videos.playing")}</Typography>
          }
          <Image
            src={url}
            alt={title}
            width={width}
            height={height} />
        </div>

        <Typography
          as="p"
          variant="secondary">
          {title}
        </Typography>
      </Link>

    </motion.div>
  )
}

export default VideoPreview
