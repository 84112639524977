import React from 'react'

import s from "./styles.module.css"
import { DigitalAssetVariantsProps } from './types'
import DigitalAssetVariant from './DigitalAssetVariant'

function DigitalAssetVariants(props: DigitalAssetVariantsProps) {
  const { assets } = props

  return (
    <div className={s.asset_list}>
      {
        assets.map((asset) => (
          <div key={asset.rarity}>
            <DigitalAssetVariant
              rarity={asset.rarity}
              supply={asset.supply}
              ratio={asset.ratio}
              heading={asset.color}
              hexColor={asset.hexColor}
            />
            {
              asset.variants && asset.variants.map(variant => (
                <DigitalAssetVariant
                  key={variant.variant}
                  heading={variant.variant}
                  supply={variant.supply}
                  ratio={variant.ratio}
                  hexColor={variant.hexColor} />
              ))
            }
          </div>
        ))
      }
    </div>
  )
}

export default DigitalAssetVariants