import React from 'react'

import Image from '@/components/ui/Image'
import Typography from '@/components/ui/Typography'
import Carousel from '@/components/ui/Carousel/Carousel'

import s from './styles.module.css'
import { DigitalAssetHeaderProps } from './types'

/**
 * Header displayed at the top of the digital assets page
 * @param props 
 * @returns 
 */
const DigitalAssetHeader = (props: DigitalAssetHeaderProps) => {
  const { title, assets, logoUrl } = props

  return (
    <div>
      <div className="w-full">
        <Carousel>
          {
            assets.map((asset) => (
              <div
                key={asset.media.src}
                className="slide">
                <Image
                  draggable={false}
                  src={asset.media.src}
                  alt={asset.media.alt}
                  className={s.image}
                  width={600}
                  height={300} />
              </div>
            ))
          }
        </Carousel>
      </div>

      <div className={s.header}>
        <Typography
          as="h2"
          className={s.title}
        >
          {title}
        </Typography>

        {
          logoUrl &&
          <div className={s.manufacturer}>
            <Image
              src={logoUrl}
              alt={title}
              width={200}
              height={150} />
          </div>
        }
      </div>

    </div>
  )
}

export default DigitalAssetHeader