import React, { useState } from 'react'

import { DoubleArrow } from '@/components/ui/Icon'
import StyledButton from '@/components/ui/StyledButton'
import { t } from '@/helpers'

import VideoPreview from '../VideoPreview'
import { VideoPreviewListProps } from './types'
import { VideoPreviewType } from '../VideoPreview/types'

import s from './styles.module.css'

/**
 * Displays a list of video previews
 * @param props 
 * @returns 
 */
const VideoPreviewList = (props: VideoPreviewListProps) => {
  const { videos, previewCount = 5, playing, basePath } = props
  const [showAll, setShowAll] = useState<boolean>(videos.length < previewCount)

  const onClick = () => setShowAll(true)

  const preview = showAll ? videos : videos.slice(0, previewCount)

  return (
    <div>
      <div className={s.videos_list}>
        {
          preview.map((video) =>
            <VideoPreview
              key={video.id}
              video={video as VideoPreviewType}
              basePath={basePath}
              playing={playing} />)
        }
      </div>{

        !showAll &&
        <StyledButton
          color="default"
          variant="outline"
          onClick={onClick}
          className={s.button}>
          <DoubleArrow />
          {t("pages.videos.viewAll")}
          <DoubleArrow />
        </StyledButton>
      }
    </div>
  )
}

export default VideoPreviewList