import React from 'react'

import Typography from '@/components/ui/Typography'
import { TeamType } from '@/network/team/types'

import TeamMember from '../TeamMember'

/**
 * Title with a list of team members
 * @param props 
 * @returns 
 */
const TeamMembers = (props: TeamType) => {
  const { team, name } = props

  return (
    <>
      <Typography as="h2" variant="primary" className="!mb-16">{name}</Typography>
      {
        // eslint-disable-next-line react/no-array-index-key
        team.map((member, index) => <TeamMember key={index} {...member} />)
      }
    </>
  )
}

export default TeamMembers
