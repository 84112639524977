import React from 'react'

import Typography from '@/components/ui/Typography'
import Box from '@/components/ui/Box'

import s from './styles.module.css'
import { OrderedListItemProps } from './types'

/**
 * Displays list item with a number (Used in About pages)
 * @param props 
 * @returns 
 */
const OrderedListItem = (props: OrderedListItemProps) => {
  const { index, heading, children } = props

  return (
    <div className={s.wrapper}>
      <Box display="flex" notched className={s.count}>{index}</Box>

      <div className={s.content}>
        <Typography
          as="h4"
          variant="primary"
          className={s.heading}>
          {heading}
        </Typography>

        <Typography
          as="p">
          {children}
        </Typography>
      </div>
    </div>
  )
}

export default OrderedListItem
