import React, { PropsWithChildren } from 'react'

import { SidebarConnectedNav } from '@/components/shared/Sidebar'
import { MasterLayout } from '@/components/shared/Layout'

import {
  ROUTE_ANNOUNCEMENTS,
  ROUTE_PRESS_BRAND_KIT
} from '@/consts'
import t from 'helpers/translation/getTranslation'

/**
 * Page layout for pages under Press
 * @param props 
 * @returns 
 */
const PressLayout = (props: { subtitle: string } & PropsWithChildren) => {
  const { children, subtitle } = props

  const navigation = [
    // {
    //   label: t("pages.press.inTheNews.title"),
    //   href: ROUTE_PRESS_IN_THE_NEWS
    // },
    {
      label: t("pages.press.brandKit.title"),
      href: ROUTE_PRESS_BRAND_KIT
    }
  ]

  return (
    <MasterLayout
      title={t("pages.press.title")}
      subtitle={subtitle}
      sidebar={(
        <SidebarConnectedNav options={navigation} />
      )}>
      {children}
    </MasterLayout>
  )
}

export default PressLayout
