import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import StyledButton from '@/components/ui/StyledButton'
import { FormGroup, Input, ValidationSummary, Label, StyledRadioButton, StyledRadioButtonGroup } from '@/components/ui/Form'
import { PlayerHttpClient } from '@/network/httpClients'
import { register as registerUser } from '@/network/auth'
import {
  USERNAME_VALIDATION,
  EMAIL_VALIDATION,
  PASSWORD_VALIDATION,
  CONFIRM_PASSWORD_VALIDATION,
  GAME_TESTER_VALIDATION,
} from '@/utils/Validation'
import { RegisterUserPayloadType } from 'types/Api'
import { GA_EVENT_ACTION_USER_REGISTERED_ACCOUNT, useGoogleTags } from 'hooks/useGoogleTags'
import t from 'helpers/translation/getTranslation'

import { RegistrationFormProps } from './types'
import s from '../styles.module.css'

const RegistrationForm = (props: RegistrationFormProps) => {
  const { onSubmit: propsOnSubmit } = props
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { event } = useGoogleTags()

  const {
    register,
    handleSubmit,
    resetField,
    watch,
    setError,
    formState: {
      errors
    }
  } = useForm<RegisterUserPayloadType>({ mode: 'onBlur' })

  // We only display the first error, so grab the key for later use.
  const firstError = (Object.keys(errors))[0]

  const onSubmit = handleSubmit(async (data) => {
    setSubmitting(true)

    // Call register API
    const { response, error } = await registerUser(data)

    if (response?.ok && propsOnSubmit) {
      propsOnSubmit(data)

      event({
        action: GA_EVENT_ACTION_USER_REGISTERED_ACCOUNT,
      })
    }
    else if (error) {
      setError('root', { message: error[0].message })
      resetField('password')
      resetField('passwordConfirmation')
      setSubmitting(false)
    }
  });

  const checkUsername = async (value: string) => {
    const res = await PlayerHttpClient.CheckUsername(value)
    const { data } = res

    if (data?.isProfanity)
      return t("forms.usernameProfanity") as string

    if (!data?.isAvailable)
      return t("forms.usernameNotAvailable") as string

    return true
  }

  // Note the usuage of autocomplete for username and password, this is to disable autofill, see https://bugs.chromium.org/p/chromium/issues/detail?id=587466#c10
  return (
    <form
      className="mb-6"
      onSubmit={onSubmit}>
      <FormGroup>
        <Input
          autoComplete="username"
          type="email"
          placeholder="Email"
          hasError={firstError === "email"}
          {...register('email', EMAIL_VALIDATION)}
        />
      </FormGroup>
      <FormGroup>
        <Input
          autoComplete="new-username"
          type="text"
          placeholder="Username"
          hasError={firstError === "username"}
          {...register('username', {
            ...USERNAME_VALIDATION,
            validate: {
              checkUsername
            }
          })}
        />
      </FormGroup>
      <FormGroup>
        <Input
          autoComplete="new-password"
          type="password"
          placeholder="Password"
          hasError={firstError === "password"}
          {...register('password', {
            ...PASSWORD_VALIDATION,
            validate: (val: string) => {
              if (watch('email').toLowerCase() === val.toLowerCase()) {
                return t("forms.passwordCannotBeEmail") as string
              }
            },
          })}
        />
      </FormGroup>

      <FormGroup>
        <Input
          autoComplete="off"
          type="password"
          placeholder="Confirm Password"
          hasError={firstError === "passwordConfirmation"}
          {...register('passwordConfirmation', {
            ...CONFIRM_PASSWORD_VALIDATION,
            validate: (val: string) => {
              if (watch('password') !== val) {
                return t("forms.passwordDoNotMatch") as string

              }
            },
          })} />
      </FormGroup>
      <FormGroup className="flex flex-wrap justify-between">
        <Label
          className="font-bbstrata mb-4 md:mb-0 "
          htmlFor="tester">
          Would you like to be a game tester?
        </Label>
        <StyledRadioButtonGroup>
          <StyledRadioButton
            id='tester_yes'
            value="true"
            {...register("tester", GAME_TESTER_VALIDATION)}>
            Yes
          </StyledRadioButton>
          <StyledRadioButton
            id='tester_no'
            value="false"
            {...register("tester", GAME_TESTER_VALIDATION)}>
            No
          </StyledRadioButton>
        </StyledRadioButtonGroup>
      </FormGroup>
      <ValidationSummary
        makeInvisible
        errors={errors} />

      <StyledButton
        disabled={submitting}
        loading={submitting}
        className={s.button}
        color="primary"
        variant="filled"
        type="submit"
        value="Register">
        {t("pages.register.buttonText")}
      </StyledButton>
    </form>
  )
}

export default RegistrationForm