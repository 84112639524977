import React from 'react'

import TokenExchange from './TokenExchange/TokenExchange'
import { TokenExchangesProps } from './types'
import s from './styles.module.css'

const TokenExchanges = (props: TokenExchangesProps) => {
  const { exchanges, type, linkText } = props
  let display = exchanges

  if (type)
    display = exchanges.filter((exchange) => exchange.type === type)

  return (
    <div className={s.exchanges}>
      {
        display.map((exchange) => (
          <TokenExchange
            key={exchange.name}
            linkText={linkText}
            className={s.exchange}
            {...exchange} />
        ))
      }
    </div>
  )
}

export default TokenExchanges